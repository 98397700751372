import React from 'react'
import './About.css'
import headerImg from '../assets/services-header.jpg'
import Header from '../components/Header'

export const About = () => {
  return (
    <div>
      <Header
        img={headerImg}
        subTitle='ABOUT US'
        title='Our STORY'
      />
      <div className="about-title">
        <h3>Crafting Extraordinary Experiences</h3>
      </div>
      <div className="about-info">
        <p>At Elsa Events, we bring over three decades of distinguished experience in curating and executing grand weddings and corporate events. Our expertise in artist management includes not only Bollywood celebrities but also a wide range of artists from India and around the world. This extensive network allows us to deliver captivating performances that enrich every event. Our success is reflected in our 100% Guest Satisfaction Index (GSI) and the retention of our entire guest database, demonstrating our commitment to excellence and client trust. As veterans in the industry, we set the benchmark for memorable and refined event experiences.</p>
        <p>Community management is a cornerstone of our service, redefining our clients' event experiences by fostering harmony and offering a superior quality of life through unparalleled experiential services. We partner with Michelin-starred, master, and regional chefs globally to craft exceptional culinary experiences, complemented by a diverse range of molecular bars. Our dedication to creating unique, high-class events has cemented our reputation as both a prisoner and pioneer in the industry, ensuring that every event we produce offers a seamless, sophisticated, and lasting impact.</p>
      </div>
    </div>
  )
}
