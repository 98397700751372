import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Grid } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import './Artists.css'
import Header from '../components/Header'
import artistHeader from '../assets/services-header.jpg'
import { ArtistBlob } from './ArtistBlob'
import { ArtistHeader } from './ArtistHeader'

import guru from '../assets/guru.jpg'
import sonuNigam from '../assets/artists/sonunigam.jpg'
import bpraak from '../assets/artists/bpraak.jpg'
import farhanAkhtar from '../assets/artists/farhanakhtar.jpg'
import shibaniAkhtar from '../assets/artists/shibhaniakhtar.jpg'
import vishalshekhar from '../assets/artists/vishalshekhar.jpg'
import shankarehsaan from '../assets/artists/shankarehsaan.jpg'
import kanikaKapoor from '../assets/kanika.webp'
import kailashkher from '../assets/artists/kailashkher.jpg'
import adnansami from '../assets/artists/adnansami.jpg'
import harshdeepkaur from '../assets/artists/harshdeepkaur.jpg'
import sachetparampara from '../assets/artists/sachetparampara.jpg'
import mika from '../assets/mika.jpg'
import maninderbuttar from '../assets/artists/maninderbuttar.jpg'
import salmanali from '../assets/artists/salmanali.jpg'

import shahrukh from '../assets/artists/shahrukh.jpg'
import malaika from '../assets/artists/malaika.jpg'
import nora from '../assets/artists/noora.jpg'
import janhvi from '../assets/artists/janhvi.jpg'
import javed from '../assets/artists/JavedAkhtar.jpg'
import ananya from '../assets/artists/ananya.jpg'
import shweta from '../assets/artists/shwetatiwari.jpg'

const singers = [
  {
    name: 'Guru Randhawa',
    img: guru
  },
  {
    name: 'Sonu Nigam',
    img: sonuNigam
  },
  {
    name: 'B Praak',
    img: bpraak
  },
  {
    name: 'Farhan Akhtar',
    img: farhanAkhtar
  },
  {
    name: 'Shibani Akhtar',
    img: shibaniAkhtar
  },
  {
    name: 'Vishal-Shekhar',
    img: vishalshekhar
  },
  {
    name: 'Shankar Ehsaan Loy',
    img: shankarehsaan
  },
  {
    name: 'Kanika Kapoor',
    img: kanikaKapoor
  },
  {
    name: 'Kailash Kher',
    img: kailashkher
  },
  {
    name: 'Harshdeep Kaur',
    img: harshdeepkaur
  },
  {
    name: 'Sachet-Parampara',
    img: sachetparampara
  },
  {
    name: 'Mika',
    img: mika
  },
  {
    name: 'Maninder Bhuttar',
    img: maninderbuttar
  },
  {
    name: 'Salman Ali',
    img: salmanali
  },
  {
    name: 'Adnan Sami',
    img: adnansami
  },
]

const celebs = [
  { name: 'Shahrukh Khan', img: shahrukh },
  { name: 'Malaika Arora', img: malaika },
  { name: 'Nora Fatehi', img: nora },
  { name: 'Janhvi Kapoor', img: janhvi },
  { name: 'Javed Akhtar', img: javed },
  { name: 'Ananya Pandey', img: ananya },
  { name: 'Shweta Tiwari', img: shweta },
]

export const Artists = () => {
  return (
    <div>
      <Header
        img={artistHeader}
        subTitle='THE BEST OF THE BEST'
        title='Our ARTISTS'
      />
      <div className="artist-container">
        <div className="artists-singer">
          <ArtistHeader title='BOLLYWOOD SINGERS' />
          <Swiper
            spaceBetween={30}
            slidesPerView={2}
            paginationStyle={{ bottom: -60 }}
            style={{ paddingTop: '24px', minHeight: '350px' }}
            grid={{
              rows: 2,
              fill: "row",
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Grid, Pagination]}
            className='singer-swiper'
          >
            {singers.map((singer) => <SwiperSlide><ArtistBlob name={singer.name} img={singer.img} /></SwiperSlide>)}
          </Swiper>
          <div className="artists-singer-content">
            {singers.map((singer) => <ArtistBlob name={singer.name} img={singer.img} />)}
          </div>
        </div>
        <div className="artists-singer">
          <ArtistHeader title='BOLLYWOOD CELEBRITIES' />
          <Swiper
            spaceBetween={30}
            slidesPerView={2}
            paginationStyle={{ bottom: -60 }}
            style={{ paddingTop: '24px', minHeight: '350px' }}
            grid={{
              rows: 2,
              fill: "row",
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Grid, Pagination]}
            className='singer-swiper'
          >
            {celebs.map((singer) => <SwiperSlide><ArtistBlob name={singer.name} img={singer.img} /></SwiperSlide>)}
          </Swiper>
          <div className="artists-singer-content">
            {celebs.map((singer) => <ArtistBlob name={singer.name} img={singer.img} />)}
          </div>
        </div>
      </div>
    </div>
  )
}
