import React from 'react'
import Header from '../components/Header'
import headerImg from '../assets/services-header.jpg'
import { Service as Serv } from '../Home/Service'
import wedding from '../assets/weddings.JPG'
import corporate from '../assets/corporate.jpg'
import art from '../assets/art.jpg'
import { BiClipboard } from "react-icons/bi";
import { BiCalendarHeart } from "react-icons/bi";
import { BiGift } from "react-icons/bi";
import { BiRocket } from "react-icons/bi";
import { BiGroup } from "react-icons/bi";
import { BiBriefcase } from "react-icons/bi";
import { BiPaint } from "react-icons/bi";
import { BiArch } from "react-icons/bi";
import { BiFlag } from "react-icons/bi";
import './Service.css'

export const Service = () => {
    return (
        <div className='service-page'>
            <Header img={headerImg} title={'Work With Us'} subTitle={'OUR SERVICES'} />
            <div className="service-pg-descr">
                <h1>Elevate Your Event Experience with Elsa Events</h1>
                <div>
                    <p>At Elsa Events, we pride ourselves on delivering exceptional event planning services tailored to meet the unique needs of our clients. Whether you're dreaming of a fairytale wedding, planning a corporate event that makes a lasting impression, or organizing an inspiring art and culture event, our experienced team is here to bring your vision to life. With meticulous attention to detail and a personalized approach, we ensure every event we manage is a resounding success.</p>
                    <p>Our comprehensive range of services includes weddings, corporate events, and art and culture events, each designed to create unforgettable experiences. From intimate gatherings to large-scale celebrations, we handle every aspect of planning and execution with professionalism and creativity. At Elsa Events, your satisfaction is our top priority, and we are dedicated to making every moment extraordinary. Explore our services to see how we can make your next event truly special.</p>
                </div>
            </div>

            <div className="service-pg-offer-heading">
                <h3>OUR</h3>
                <h3>WORK</h3>
                <h2>WHAT WE OFFER</h2>
            </div>

            <div className="service-pg-list">
                <div className="service-pg-list-item">
                    <Serv
                        imgSrc={wedding}
                        title='WEDDINGS'
                        descr={'At Elsa Events, we understand that your wedding day is one of the most important moments in your life. Our dedicated team of wedding planners will work tirelessly to ensure every detail is perfect, allowing you to enjoy your special day without any stress.'}
                    />
                    <div className="service-img-desk">
                        <img src={wedding} alt="a bride and groom" />
                        <div>
                            <h2>WEDDINGS</h2>
                            <p>At Elsa Events, we understand that your wedding day is one of the most important moments in your life. Our dedicated team of wedding planners will work tirelessly to ensure every detail is perfect, allowing you to enjoy your special day without any stress.</p>
                        </div>
                    </div>
                    <ul>
                        <li>
                            <BiClipboard color='#d1c6b6' size={36} />
                            <div className="service-icon-txt">
                                <h4>Full-Service Planning</h4>
                                <p>From venue selection to vendor management, we handle every aspect of your wedding planning process.</p>
                            </div>
                        </li>
                        <li>
                            <BiCalendarHeart color='#d1c6b6' size={36} />
                            <div className="service-icon-txt">
                                <h4>Day-of Coordination</h4>
                                <p>Our team ensures everything runs smoothly on your big day, managing timelines and coordinating with vendors.</p>
                            </div>
                        </li>
                        <li>
                            <BiGift color='#d1c6b6' size={36} />
                            <div className="service-icon-txt">
                                <h4>Custom Wedding Packages</h4>
                                <p>Tailored to fit your unique style and budget, ensuring your wedding is as unique as your love story.</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="service-pg-list-item">
                    <Serv
                        imgSrc={corporate}
                        title={'CORPORATE EVENTS'}
                        descr={'Elsa Events specializes in creating impactful corporate events that reflect your company’s brand and values. Whether it’s a conference, product launch, or team-building retreat, we deliver professional events that achieve your business objectives.'}
                    />
                    <div className="service-img-desk">
                        <img src={corporate} alt="corporate event" />
                        <div>
                            <h2>CORPORATE EVENTS</h2>
                            <p>Elsa Events specializes in creating impactful corporate events that reflect your company’s brand and values. Whether it’s a conference, product launch, or team-building retreat, we deliver professional events that achieve your business objectives.</p>
                        </div>
                    </div>
                    <ul>
                        <li>
                            <BiRocket color='#d1c6b6' size={36} />
                            <div className="service-icon-txt">
                                <h4>Product Launches</h4>
                                <p>Creative and strategic planning to make your new product stand out.</p>
                            </div>
                        </li>
                        <li>
                            <BiGroup color='#d1c6b6' size={36} />
                            <div className="service-icon-txt">
                                <h4>Team-Building Events</h4>
                                <p>Engaging activities designed to strengthen team dynamics and morale.</p>
                            </div>
                        </li>
                        <li>
                            <BiBriefcase color='#d1c6b6' size={36} />
                            <div className="service-icon-txt">
                                <h4>Conference & Seminar Planning</h4>
                                <p>Comprehensive services from venue booking to technical support.</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="service-pg-list-item">
                    <Serv
                        imgSrc={art}
                        title={'ART & CULTURE EVENTS'}
                        descr={'Elsa Events is passionate about celebrating art and culture through meticulously planned events. Whether you’re hosting an art exhibition, music festival, or cultural fair, our team will bring your vision to life with creativity and precision.'}
                    />
                     <div className="service-img-desk">
                        <img src={art} alt="theatre performance" />
                        <div>
                            <h2>ART & CULTURE EVENTS</h2>
                            <p>Elsa Events is passionate about celebrating art and culture through meticulously planned events. Whether you’re hosting an art exhibition, music festival, or cultural fair, our team will bring your vision to life with creativity and precision.</p>
                        </div>
                    </div>
                    <ul>
                        <li>
                            <BiFlag color='#d1c6b6' size={36} />
                            <div className="service-icon-txt">
                                <h4>Cultural Fairs and Celebrations</h4>
                                <p>Showcasing diverse cultures through well-organized, vibrant events.</p>
                            </div>
                        </li>
                        <li>
                            <BiArch color='#d1c6b6' size={36} />
                            <div className="service-icon-txt">
                                <h4>Theatre</h4>
                                <p>From logistics to performer coordination, we handle it all.</p>
                            </div>
                        </li>
                        <li>
                            <BiPaint color='#d1c6b6' size={36} />
                            <div className="service-icon-txt">
                                <h4>Art Exhibitions</h4>
                                <p>Curating memorable experiences for artists and attendees alike.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
