import React from 'react'
import './BlogCard.css'

export const BlogCard = ({img, title}) => {
  return (
    <div className='blog-card'>
        <img src={img} alt={title} />
        <h3>{title}</h3>
    </div>
  )
}
