import React from 'react'
import './Privacy.css'
import Header from '../components/Header'
import header from '../assets/services-header.jpg'

export const Privacy = () => {
    return (
        <div>
            <Header
                img={header}
                title={'Privacy Policy'}
            />
            <div className="privacy-policy">
                <h2>PRIVACY POLICY</h2>
                <h3>WHO WE ARE</h3>
                <p>Elsa Events operates our website address: https://www.elsaevents.com/
                    We value the trust of our customers and are committed to respecting the privacy of our customers and users of this Sites and Services. Elsa Events is the sole collector of the information you provide us. This Privacy Notice provides mandatory information as required under Articles 13 and 14 of the European General Data Protection Regulation (GDPR) regarding the transparency of personal data processing.</p>
                <h3>PERSONAL DATA WE COLLECT AND WHY</h3>
                <h4>CONTACT FORM</h4>
                <p>We have access to/collect information that you voluntarily give us through our contact page. This includes name, email address, and phone number.</p>
                <h4>COOKIES</h4>
                <p>This website use cookies installed by Google Analytics. These cookies are used to calculate visitor, session, campaign data and keep track of site usage. The cookies store information anonymously and assign a randomly generated number to identify unique visitors. For more on Google’s Privacy Policy please visit: https://policies.google.com/privacy/update.</p>
                <h4>HOW WE USE YOUR DATA</h4>
                <p>We will use your information to respond to you, regarding the reason you contacted us and for customer service purposes. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request. Unless you ask us not to, we may contact you via email in the future. We will never contact you via phone for marketing purposes. You may opt out of any future contact from us at any time. To do so, please contact admin@elsaevents.com.</p>
                <h4>YOUR RIGHTS TO YOUR PERSONAL DATA </h4>
                <ul>
                    <li>to access your personal information and be informed about its use</li>
                    <li>to correct your personal information</li>
                    <li>to have your personal information deleted</li>
                    <li>to restrict the use of your personal information</li>
                    <li>to object to the use of your personal information</li>
                    <li>to complain to a supervisory authority</li>
                    <li>to withdraw your consent to the use of your personal information</li>
                    <li>to use appropriate technical and organizational measures such as storing personal information on secure servers only
                        granting access to personal information where necessary</li>
                </ul>
                <p>If you wish to exercise these rights, please contact admin@elsaevents.com.</p>
            </div>
        </div>
    )
}
