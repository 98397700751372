import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'
import logo2 from './assets/longlogo2light.svg'

const Footer = () => {
  
  return (
    <footer>
        <h3>Company</h3>
        <Link to='/about'>About</Link>
        <Link to='/services'>Services</Link>
        <Link to='/contact'>Contact</Link>
        <h3>Navigate</h3>
        <Link to='/portfolio'>Portfolio</Link>
        <Link to='/artists'>Artists</Link>
        <Link to='/blog'>Blog</Link>
        <Link to='/privacy'>Privacy Policy</Link>
        <img src={logo2} alt="short logo of elsa events" />
        <p>All rights reserved.</p>
    </footer>
  )
}

export default Footer