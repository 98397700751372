import React from 'react'
import './Blog.css'
import Header from '../components/Header'
import blogHeader from '../assets/services-header.jpg'
import { ArtSection } from './ArtSection'

export const Blog = () => {
  return (
    <div>
      <Header
        img={blogHeader}
        subTitle='ALL THE INSPO YOU NEED'
        title='Blog'
      />
      <ArtSection/>
    </div>
  )
}
