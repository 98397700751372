import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import './BlogPost.css'

const sectionMap = {
    'artandculture': 'ART & CULTURE'
}

export const BlogPost = () => {
    const [json, setJson] = useState(undefined);
    const [img, setImg] = useState()
    const { id } = useParams();
    const [section, number] = id.split('-')

    useEffect(() => {
        import(`../blogAssets/${section.toLowerCase()}/${section.toLowerCase()}.json`)
            .then((res) => setJson(res.default?.[number]))
            .catch(_ => null);
    }, [id, section, number]);

    useEffect(() => {
        json && import(`../blogAssets/${section?.toLowerCase()}/${json.image}`)
            .then((res) => setImg(res.default))
            .catch(_ => null);
    }, [json, section])

    const sectionName = sectionMap[section?.toLowerCase() || '']
    const title = json?.title
    const desc = json?.description
    const sectionArr = json?.sections

    return (
        <div className='blog-post'>
            <h3>{sectionName}</h3>
            <h1>{title}</h1>
            <div className="blog-post-img-container">
                <p>{desc}</p>
                {img && <img src={img} alt="" />}
            </div>
            <div className="divider"></div>
            {sectionArr?.map((sec) => {
                const { title: secTitle, description: secDescr, list: secList } = sec
                return (
                    <>
                        <h2>{secTitle}</h2>
                        <p>{secDescr}</p>
                        <ul>
                            {secList?.map((listItem) => {
                                return (
                                    <li key={listItem.title}>
                                        <h5>{listItem.title}</h5>
                                        <p>{listItem.description}</p>
                                    </li>)
                            })}
                        </ul>
                    </>
                )
            })}
        </div>
    )
}
