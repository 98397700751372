import React from 'react'
import './HeadingPadded.css'

export const HeadingPadded = ({title}) => {
  return (
    <div id='shared-heading-padded'>
        <h2>{title}</h2>
    </div>
  )
}
