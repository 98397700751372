import React from 'react'
import landing1 from '../assets/landing1.jpg'
import landing1Desk from '../assets/landing1-desktop.jpg'
import './Home.css'
import './HomeDesktop.css'
import {Service} from './Service'
import wedding from '../assets/weddings.JPG'
import corporate from '../assets/corporate.jpg'
import art from '../assets/art.jpg'
import guru from '../assets/guru.jpg'
import mika from '../assets/mika.jpg'
import kanika from '../assets/kanika.webp'
import landing2 from '../assets/landing2.webp'
import { useNavigate } from 'react-router-dom'


const Home = () => {
const navigate = useNavigate()
    const navigateToService = () => {
        navigate('/services')
    }
    const navigateToPortfolio = () => {
        navigate('/portfolio')
    }

    return (
        <div className='home'>
            <div className='home-img-con'>
                <a href='tel:+919990303802'>
                    <button>CALL NOW</button>
                </a>
                <picture>
                    <source media='(min-width: 480px)' height={186} srcSet={landing1Desk} />
                    <img src={landing1} alt="florals and table setting" />
                </picture>
            </div>
            <div id='home-circle-grid'>

                <div className='home-circle'>
                    <h4>WELCOME TO</h4>
                    <h4>ELSA EVENTS</h4>

                </div>
                <div className="home-about">
                    <h3>UNPARALLELED
                        <br />
                        EVENT PLANNING
                    </h3>
                    <p>
                        The Elsa Events experience is unlike any other. Our event-planning team will guide you every step of the way, ensuring a stress-free experience from start to finish.
                        <br />
                        We have a network of some of the best artists in India- singers, celebrities, and influencers- to help you create your dream event. Our production team will ensure your event will be one to remember.
                        <br />
                        When it's showtime, our staff will work tirelessly to guarantee your event is effortless and unforgettable.
                    </p>
                </div>
            </div>

            <div className="home-services">
                <Service
                    imgSrc={wedding}
                    title='WEDDINGS'
                    descr="With every last detail taken care of, we're here to ensure the day you've always dreamed of will be the day you'll never forget"
                />
                <Service
                    imgSrc={corporate}
                    title='CORPORATE'
                    descr="Wow your guests with a unique corporate experience that aligns with your company's mission and elevates your brand"
                />
                <Service
                    imgSrc={art}
                    title='ART & CULTURE'
                    descr="Engage with the community by attending or hosting art and culture events beyond anything you've ever seen"
                />
                <button onClick={navigateToService}>
                    VIEW OUR SERVICES
                </button>
            </div>

            <div className="home-artists">
                <div className="artists-header">
                    <h3>ARTISTS WE WORK WITH</h3>
                </div>
                <img src={guru} alt="Guru Randhawa Singer" />
                <img src={kanika} alt="Kanika Kapoor Singer" />
                <img src={mika} alt="Mika Singh Singer" />
            </div>

            <div className='arch-grid'>
                <div className="arch-sideway">
                    <div className="left-arch"></div>
                    <img src={landing2} alt='flower decor' className="right-arch" />
                </div>

                <div className="home-promise">
                    <h4>OUR PROMISE</h4>
                    <h2>Memories that last a lifetime</h2>
                    <p>We bring years of experience and a proven track record in both corporate events and weddings. Our team of dedicated professionals ensure every detail is meticulously handled, guaranteeing a seemless and memorable experience. </p>
                    <button onClick={navigateToPortfolio}>
                        VIEW PORTFOLIO
                    </button>
                </div>
            </div>
        </div>

    )
}

export default Home