import React from 'react'
import './Artists.css'

export const ArtistBlob = ({img, name}) => {
  return (
    <div className='artist-blob'>
        <img src={img} alt={name} />
        <h4>{name}</h4>
    </div>
  )
}
