import React from 'react'
import Header from '../components/Header'
import aboutHeader from '../assets/about-header.jpg'
import { ComingSoon } from '../components/ComingSoon'

export const Portfolio = () => {
  return (
    <div>
      <Header
        img={aboutHeader}
        subTitle={'EXPLORE OUR WORK'}
        title={'Portfolio'}
      />
      <ComingSoon/>
    </div>
  )
}