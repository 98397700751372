import React from 'react'
import './Service.css'

export const Service = ({ imgSrc, title, descr }) => {
    return (
        <div className='service'>
            <div className="arch">
                <img src={imgSrc} alt="a bride and groom" />
            </div>
            <h3>{title}</h3>
            <p>{descr}</p>
        </div>
    )
}
