import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Contact.css'
import Header from '../components/Header'
import contacthead from '../assets/contact2.jpg'
import { BounceLoader } from 'react-spinners';

const phoneReg = /^\d+$/;

export const Contact = () => {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTelephone] = useState('')
  const [msg, setMsg] = useState('')
  const [loading, setLoading] = useState(false)

  const clearValues = () => {
    setName('')
    setEmail('')
    setTelephone('')
    setMsg('')
  }

  const onTelChange = (e) => {
    if (e.target.value?.length <= 10 && phoneReg.test(e.target.value)) {
      setTelephone(e.target.value)
    }
  }

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true)
    emailjs
      .send('service_9rh8gkn', 'template_at458gc', {
        from_name: name,
        email,
        phone: tel,
        message: msg
      }, { publicKey: 'VarXfDeKExOQ6r1NJ' })
      .then(
        () => {
          setLoading(false)
          clearValues()
          toast.success("Message Sent", {
            theme: "light"
          })
          console.log('SUCCESS!');
        },
        (error) => {
          setLoading(false)
          clearValues()
          toast.error("Oops... something went wrong", {
            theme: "light"
          });
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div>
      <Header
        img={contacthead}
        subTitle='DROP US A LINE'
        title='Contact Us'
      />
      <div className="contact-content">
        <div className='contact-header'>
          <h3>Let's Get In Touch</h3>
          <h5>Or just reach out to sales@elsaevents.com</h5>
        </div>
        <div className='contact-form'>
          <input value={name} onChange={(e) => setName(e.target.value)} type="text" name='name' maxLength={30} placeholder='Full Name *' />
          <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" name='email' maxLength={30} placeholder='Email *' />
          <input value={tel} onChange={onTelChange} type="tel" name='phone' placeholder='Phone *' />
          <textarea value={msg} onChange={(e) => setMsg(e.target.value)} rows={4} maxLength={300} placeholder='I have an event on ... *'></textarea>
          <button onClick={sendEmail} disabled={!name || !email || !tel || !msg}>Send Message</button>
        </div>
        {loading && <div className="contact-loading">
          <BounceLoader color='#6f4335' />
        </div>}
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
      />
    </div>
  )
}
