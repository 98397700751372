import React, { useState } from 'react'
import './Navbar.css'
import longlogo from './assets/longlogo1.svg'
import longlogodesk from './assets/longlogo2.svg'
import { Link, NavLink } from 'react-router-dom'

const routes = {
    'HOME': '/',
    'SERVICES': '/services',
    'PORTFOLIO': '/portfolio',
    'ABOUT': '/about',
    'ARTISTS': '/artists',
    'BLOG': '/blog',
    'CONTACT': '/contact',
}

const Navbar = () => {

    const [openNav, setOpenNav] = useState(false)

    const onHamburgerClick = () => {
        setOpenNav(!openNav)
    }

    const closeLink = () => {
        setOpenNav(false)
    }

    return (
        <nav className={openNav ? 'nav-main-active nav-main' : 'nav-main'}>
            <div className='nav-logo-box'>
                <Link to={'/'}>
                    <picture>
                        <source media='(min-width: 480px)' height={186} srcSet={longlogodesk} />
                        <img src={longlogo} alt="" />
                    </picture>
                </Link>

                <button className={openNav ? 'burger burger-active' : 'burger'} onClick={onHamburgerClick}>
                    <div className="line1"></div>
                    <div className="line2"></div>
                </button>
            </div>
            <ul className={openNav ? 'active-links-nav main-nav' : 'nav-inactive main-nav'}>
                {Object.entries(routes).map(([routeName, routeVal]) => {
                    return (
                        <li><NavLink onClick={closeLink} to={routeVal}>{routeName}</NavLink></li>

                    )
                })}
            </ul>
            <div className={openNav ? "social-nav-active" : 'nav-inactive'}>
                <ul className={openNav ? 'active-links-nav' : 'nav-inactive'}>
                    {Object.entries(routes).slice(4).map(([routeName, routeVal]) => {
                        return (
                            <li><NavLink onClick={closeLink} to={routeVal}>{routeName}</NavLink></li>

                        )
                    })}
                </ul>
            </div>
        </nav>

    )
}

export default Navbar