import React from 'react'
import './Artists.css'

export const ArtistHeader = ({title}) => {
  return (
    <div className='artist-title'>
        <h2>{title}</h2>
    </div>
  )
}
