import React from 'react'
import './Header.css'

const Header = ({ img, title, subTitle }) => {
    return (
        <div className='header-shared'>
            <img alt='neutral flowers' src={img} />
            <div>
                <h4>{subTitle}</h4>
                <h3>{title}</h3>
            </div>
        </div>
    )
}

export default Header