import React from 'react'
import './Blog.css'
import { HeadingPadded } from '../components/HeadingPadded'
import { BlogCard } from './BlogCard'
import { Link } from 'react-router-dom'
import json from '../blogAssets/artandculture/artandculture.json'

export const ArtSection = () => {
    return (
        <div className="blog-post-list">
            <HeadingPadded
                title='RECENT IN ARTIST & CULTURE'
            />
            {Object.entries(json).map(([key, item]) => {
                return (
                    <Link to={`/blog/artandculture-${key}`} key={key}>
                        <BlogCard
                            img={require(`../blogAssets/artandculture/${item.image}`)}
                            title={item.title}
                        />
                    </Link>
                )
            })}
        </div>
    )
}
