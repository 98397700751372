import React from 'react'
import './ComingSoon.css'

export const ComingSoon = () => {
  return (
    <div className='comingsoon'>
        <h2>Stay tuned as we update this page!</h2>
        <p>We're updating this website everyday. If you can't find what you're loking for then please Contact Us.</p>
    </div>
  )
}
