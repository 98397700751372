import { Route, Routes } from 'react-router-dom';
import Home from './Home/Home';
import Navbar from './Navbar';
import Footer from './Footer';
import { Service } from './Service';
import { Portfolio } from './Portfolio/Portfolio';
import { About } from './About';
import { Artists } from './Artists';
import { Blog } from './Blog';
import { Contact } from './Contact';
import { Privacy } from './Privacy';
import { Whatsapp } from './Whatsapp';
import { BlogPost } from './Blog/BlogPost';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/services' element={<Service/>}/>
        <Route path='/portfolio' element={<Portfolio/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/artists' element={<Artists/>}/>
        <Route path='/blog' element={<Blog/>}/>
        <Route path='/blog/:id' element={<BlogPost/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/privacy' element={<Privacy/>}/>
      </Routes>
      <Whatsapp/>
      <Footer/>
    </div>
  );
}

export default App;
