import React, { useEffect, useState } from 'react'
import { Tooltip as ReactTooltip } from "react-tooltip";
import whatsapp from './assets/whatsapp.svg'

const text = 'I found your number on Elsa Event\'s website. Can you help me with more information?'
const textPayload = text.split(' ').join('%20')
export const Whatsapp = () => {
    const [openTooltip, setOpenTooltip] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setOpenTooltip(true)
        }, 3000)
        setTimeout(() => {
            setOpenTooltip(false)
        }, 5000)
    }, [])

    return (
        <div id='whatsapp' data-tooltip-id='whatsapp-tooltip'>
            <a aria-label="Chat on WhatsApp" href={`https://wa.me/919990303802?text=${textPayload}`}>
                <img height={36} width={36} alt="Chat on WhatsApp" src={whatsapp} />
            </a >
            <ReactTooltip
            id='whatsapp-tooltip'
            place='left'
            content='Chat with us on Whatsapp'
            isOpen={openTooltip}
            style={{ backgroundColor: "#d1c6b6", color: "#887769" }}
            />
        </div>
    )
}
